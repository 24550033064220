import "./globals.css";
import { AppProps } from "next/app";
import getConfig from "next/config";
import Script from "next/script";
import { GoogleAnalytics, GoogleTagManager } from "@next/third-parties/google";
import { Play } from "next/font/google";
import Image from "next/image";
import RootLayout from "./layout";

const play = Play({
  weight: ["400", "700"],
  subsets: ["latin", "latin-ext", "cyrillic-ext", "cyrillic"],
});

function CustomApp({ Component, pageProps }: AppProps) {
  const { publicRuntimeConfig } = getConfig();
  const googleTagManager = publicRuntimeConfig.GOOGLE_TAG_MANAGER_ID || null;
  const googleAnalitycsId = publicRuntimeConfig.GOOGLE_ANALITYCS_ID || null;
  const facebookPixelId = publicRuntimeConfig.FACEBOOK_PIXEL_ID || null;

  return (
    <>
      <RootLayout>
        <Script id="facebook-pixel" strategy="afterInteractive">
          {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${facebookPixelId}');
          fbq('track', 'PageView');
        `}
        </Script>
        <noscript>
          <Image
            height="1"
            alt="pixel"
            width="1"
            style={{ display: "none" }}
            src={`https://www.facebook.com/tr?id=${facebookPixelId}&ev=PageView&noscript=1`}
          />
        </noscript>
        <Script strategy="afterInteractive" src="https://js.stripe.com/v3/buy-button.js" async />
        <GoogleTagManager gtmId={googleTagManager} />
        <GoogleAnalytics gaId={googleAnalitycsId} />
        <main className={`${play.className}`}>
          <Component {...pageProps} />
        </main>
      </RootLayout>
    </>
  );
}
export default CustomApp;
